import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import './font.css'

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.font.main};
    font-weight: lighter;
    font-style: normal;
    scroll-behavior: smooth;
    height: 100%;
  }
  body {
    letter-spacing: -0.02em;
    background: ${({ theme }) => theme.colors.cream};
    color: ${({ theme }) => theme.colors.grey};
    overflow-x: hidden;
  }
  h1 {
    line-height: clamp(1.5rem, 6vw, 3.3125rem);
  }
  .pointer{
    cursor: pointer;
  }
`

export const theme = {
    font: {
        main: "silka-light, sans-serif"
    },
    colors: {
        cream: "#EFEEEA",
        white: "#fff",
        grey: "#333",
    },
    breakpoints: {
        mobile: "only screen and (max-width: 46rem)",
        tablet: "only screen and (max-width: 60rem)",
    },
    spacing: {
        small: "2rem",
        medium: "3rem",
        large: "6rem",
    }
}