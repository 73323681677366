import  React, { useState } from  'react';

export  const  StateContext = React.createContext();

export  const  StateProvider = ({ children }) => {
  const [click, setClick] = useState(false);

  return (
    <StateContext.Provider
      value={{
        click,
        setClick
      }}
    >
      {children}
    </StateContext.Provider>
  );
};