import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const url = process.env.COCKPIT_HOST
const token = process.env.COCKPIT_ACCESS_TOKEN

export const client = new ApolloClient({
  link: new HttpLink({
    uri: url + '/api/graphql/query?token=' + token,
    fetch,
    opts: {
      credentials: 'same-origin',
    }
  }),
  cache: new InMemoryCache()
});
