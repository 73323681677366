import React from 'react'
import { ThemeProvider } from 'styled-components'
import { StateProvider }  from './src/providers/StateProvider'
import { GlobalStyle, theme } from './src/theme/global-style'
import { ApolloProvider } from '@apollo/client';
import { client } from './src/providers/client';



export const wrapPageElement = ({ element }) => {

    
    return (
            <ThemeProvider theme={theme}>
                <StateProvider>
                    <ApolloProvider client={client}>
                            <GlobalStyle/>
                                {element}
                    </ApolloProvider>
                </StateProvider>
            </ThemeProvider> 
    );
};